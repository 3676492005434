import React, { useEffect } from 'react';
import { UiView, UiText } from '@dex/bubl-ui';
import styles from "./BitMovinPlayer.module.scss";
import postMessage from '../../functions/postMessage';
import { listeners } from 'process';

const BitMovinPlayer: React.FC<BitMovinPlayerProps> = (props) => {

    const { video } = props;

    video.isApp = true;

    var settings: any = {
        key: '4db272ef-afb9-4215-8b03-2341fb5384e9',
        cast: true,
        analytics: {
            key: '87C7EE73-4D3F-42E9-860B-D58017896BFC',
            videoId: video.id,
            title: video.title,
            customUserId: video.userId,
        },
        events: {
            adclicked: (data: any) => {

                if (!video.isApp) return;

                const { clickThroughUrl, timestamp, type } = data;

                postMessage.send({ action: "adClick", url: clickThroughUrl, timestamp: timestamp, type: type });

            }
        },
        // https://bitmovin.com/docs/player/api-reference/web/web-sdk-api-reference-v8#/player/web/8/docs/enums/core_events.playerevent.html
    };

    if (video.ads) {

        settings.advertising = {
            adBreaks: video.ads,
        }

    }

    if (video?.screen?.tags) {

        if (video.screen.tags.host) settings.analytics.customData1 = video.screen.tags.host.join(" ");
        if (video.screen.tags.category) settings.analytics.customData2 = video.screen.tags.category.join(" ");
        if (video.screen.tags.season) settings.analytics.customData3 = video.screen.tags.season.join(" ");

    }

    const streamTypes: any = {
        mp4: "progressive",
        hls: "hls",
        dash: "dash",
        smooth: "smooth",
    }

    var source: any = {};

    source[streamTypes[video.type] || "hls"] = video.uri;

    useEffect(() => {

        //@ts-ignore
        const player = new bitmovin.player.Player(document.getElementById('bmPlayer'), settings);

        player.load(source);

    }, []);

    // useEffect(() => {

    //     if (!video.isApp) return;

    //     const listener = (event: any) => {

    //         event.preventDefault();

    //         event.returnValue = '';

    //     };

    //     window.addEventListener('beforeunload', listener);

    //     return () => {

    //         window.removeEventListener('beforeunload', listener);

    //     }

    // }, []);

    return (

        <UiView id="bmPlayer" className={styles.player}>
        </UiView>

    )

}

interface BitMovinPlayerProps {
    [key: string]: any,
}

export default BitMovinPlayer;