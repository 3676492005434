import React from 'react'

import { IoPlayBack, IoPlayForward, IoPause, IoPlay, IoVolumeHigh, IoVolumeMute, IoPlaySharp, IoPauseSharp, IoVolumeHighSharp, IoVolumeMuteSharp } from 'react-icons/io5';

import 'rc-slider/assets/index.css';
import styles from './PlayPause.module.scss'

import { UiLink, UiView } from '@dex/bubl-ui';

const PlayPause: React.FC<PlayPauseProps> = (props) => {

    const { onHandlePlayPause } = props;

    return (
        <UiLink
            className={styles.playPauseWrapper}
            onClick={onHandlePlayPause}
        >

        </UiLink>
    )

};

interface PlayPauseProps {
    [key: string]: any;
}

export default PlayPause
