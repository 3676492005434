import { useRouter } from "@dex/bubl-ui";

import PlayerScreen from "../screens/PlayerScreen";

import { hash } from "@dex/bubl-helpers";

export default () => {

    useRouter.addRoute({
        name: "Home",
        component: PlayerScreen,
        link: "/",
        options: {},
    });

    return process.env.NODE_ENV === "development" ? hash(useRouter.routes) : "";


};
