import React, { useEffect, useMemo } from 'react';
import { useStore } from '@dex/bubl-ui';
import { config, AppWrapper, AppErrorWrapper } from '@dex/bubl-web';
import { apiSetToken } from '@dex/bubl-fetch';

import './styles/Web.scss';
import Routes from './routes/Routes';

export default function App() {

    config.init({
        siteName: "Huddle Player",
    });

    const [key] = useStore("appKey", 1);

    const routeHash = Routes();

    useEffect(() => {

        const params = new URLSearchParams(window.location.search);
        const token = params.get('token');

        if (token) apiSetToken(token);

    }, []);

    return useMemo(() => {

        return (

            <>

                <AppErrorWrapper>

                    <AppWrapper
                        key={key + routeHash}
                        loadingScreen={() => React.Fragment}
                        noLogin={true}
                    />

                </AppErrorWrapper>

            </>

        )

    }, [key, routeHash]);

}
