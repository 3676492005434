import React from 'react';
import { UiImage } from '@dex/bubl-ui';
import styles from "./Poster.module.scss";

const Poster: React.FC<PosterProps> = (props) => {

    const { video } = props;

    return (

        <>
            <UiImage
                className={styles.poster}
                type={'thumb'}
                filters={{ width: 1600, height: 900 }}
                resizeMode="cover"
                src={video.poster || { fileId: "-" }}
            />
        </>
    )

}

interface PosterProps {
    [key: string]: any,
}

export default Poster;