import React, { useState, forwardRef, useRef, useEffect } from 'react'

import { IoPlayBack, IoPlayForward, IoPause, IoPlay, IoVolumeHigh, IoVolumeMute, IoPlaySharp, IoPauseSharp, IoVolumeHighSharp, IoVolumeMuteSharp } from 'react-icons/io5';
import { RiFullscreenFill } from 'react-icons/ri';
import Slider, { SliderTooltip } from 'rc-slider';

import 'rc-slider/assets/index.css';
import styles from './Controllers.module.scss'

import { UiLink, UiText, UiView } from '@dex/bubl-ui';

const Controllers: React.FC<ControllersProps> = forwardRef((props, ref: any) => {

    const { Handle } = Slider;

    const {
        title,
        elapsedTime,
        totalDuration,
        settings,
        onHandlePlayPause,
        onHandleMute,
        onHandleForward,
        onHandleRewind,
        onHandleVolume,
        onHandlePlaybackRate,
        onHandleFullscreen,
        onHandleProgress,
        hideControls,
    } = props;

    const [popup, setPopup] = useState(false);

    const handleTooltip = (props: any) => {

        const { value, dragging, index, ...restProps } = props;

        return (
            <SliderTooltip
                prefixCls="rc-slider-tooltip"
                overlay={`${value} %`}
                visible={dragging}
                placement="top"
                key={index}
            >
                <Handle value={value} {...restProps} />
            </SliderTooltip>
        )
    }


    return (
        <UiView
            forwardedRef={ref}
            className={[styles.controllersWrapper, hideControls ? styles.hideControls : '']}
        >

            <UiView className={styles.bottomContainer}>

                <UiView className={styles.progress}>

                    <Slider
                        min={0}
                        max={100}
                        step={.01}
                        value={settings.seek * 100}
                        className={styles.progressSlider}
                        onChange={onHandleProgress}
                    // handle={handleTooltip}
                    />

                    <Loaded value={settings.loaded} />

                </UiView>

                <UiView className={styles.bottomIcons}>

                    <UiLink
                        className={styles.controlButton}
                        onClick={onHandlePlayPause}
                    >

                        {settings.playing && <IoPauseSharp className={styles.bottomIcon} />}

                        {!settings.playing && <IoPlaySharp className={styles.bottomIcon} />}

                    </UiLink>

                    <UiView className={styles.volumeControllers}>

                        <UiView className={styles.volumeControllerInner}>

                            <UiLink onClick={onHandleMute} className={styles.controlButton}>
                                {!settings.muted && <IoVolumeHighSharp className={styles.bottomIcon} />}

                                {settings.muted && <IoVolumeMuteSharp className={styles.bottomIcon} />}
                            </UiLink>

                            <Slider
                                min={0}
                                max={100}
                                value={settings.volume * 100}
                                className={styles.volumeSlider}
                                onChange={onHandleVolume}
                            />

                            <UiText tag={"span"} className={styles.time}>{elapsedTime} / {totalDuration}</UiText>

                        </UiView>

                    </UiView>


                    <UiView className={styles.speed}>
                        <UiLink
                            className={styles.controlButton}
                            onClick={() => setPopup(!popup)}
                        >
                            <UiText tag={"span"}>{settings.playbackRate}x</UiText>
                        </UiLink>

                        {popup &&
                            <PopUp
                                setPlaybackRate={onHandlePlaybackRate}
                                active={settings.playbackRate}
                                setPopup={setPopup}
                            />
                        }

                    </UiView>

                    <UiLink
                        className={styles.controlButton}
                        onClick={onHandleFullscreen}
                    >
                        <RiFullscreenFill className={styles.bottomIcon} />
                    </UiLink>

                </UiView>

            </UiView>

        </UiView>
    )
});

const PopUp = (props: any) => {

    const handleClick = (item: any) => {
        props.setPlaybackRate(item);
        props.setPopup((prev: any) => !prev);
    }

    return (
        <UiView className={styles.popup}>
            {[0.5, 1, 1.5, 2].map((item: number, index) => (
                <UiView
                    key={index}
                    className={props.active === item ? styles.activeItem : ''}
                    onClick={handleClick.bind(null, item)}
                >
                    <UiText tag={"span"}>{item}x</UiText>
                </UiView>
            ))}
        </UiView>
    )

}

interface ControllersProps {
    [key: string]: any;
}

export default Controllers



const Loaded: React.FC<LoadedProps> = (props) => {

    const { value } = props;

    const elRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        if (!elRef.current) return;

        elRef.current.style.width = `${value * 100}%`;

    }, [value]);

    return (
        <UiView forwardedRef={elRef} className={styles.loadedBar} />
    )
}

interface LoadedProps {
    [key: string]: any;
}