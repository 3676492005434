import { apiGet, options, response } from "@dex/bubl-fetch"; // eslint-disable-line @typescript-eslint/no-unused-vars

const endpoint = 'WatchVideos';

const WatchApi = {

    getPlayer: (id: string, options: options = {}): response => {

        return apiGet({
            url: endpoint + "/public/" + id + "/player",
            cache: true,
            ...options
        })

    },

}

export default WatchApi;