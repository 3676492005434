const postMessage = {

    connect: () => {

        postMessage.send({ name: "connect" });

    },
    send: (message: any) => {

        if (typeof message === "object") message = JSON.stringify(message);

        //@ts-ignore
        if (window.ReactNativeWebView) {
            //@ts-ignore
            window.ReactNativeWebView.postMessage(message);
            //@ts-ignore
        } else if (window.postMessage) {
            //@ts-ignore
            window.postMessage(message);
        } else {
            // console.warn(message);
            return;
        }

    },
    listen: (eventName: string, callback: any) => {

        const listener = (event: any) => {

            if (event && event.data && event.data.name === eventName) {
                callback(event.data);
            }

        }

        //@ts-ignore
        window.receiveMessage = listener;

        return () => { };

        // document.addEventListener("hello", listener, false);

        // return () => {
        //     document.removeEventListener("hello", listener)
        // }

    }

};

export default postMessage;
